/** @jsx jsx */

import { Layout, ContactForm } from "components";
import { Text, Heading, jsx, Flex } from "theme-ui";
import { center } from "utils";

const Abgebrochen = () => {
  return (
    <Layout>
      <Flex
        sx={{
          gridColumn: center,
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "700px",
          my: [4, 6],
        }}
      >
        <Heading as="h2" sx={{ fontSize: 6, my: 3 }}>
          Abgebrochen
        </Heading>
        <Text sx={{ fontFamily: "body", fontSize: 4 }}>
          Ihr Buchung war nicht erfolgreich. Ihr Konto wurde nicht belastet.
        </Text>
        <ContactForm sx={{ my: [4, 6] }}>
          <Heading as="h3" sx={{ my: 3, fontSize: 4 }}>
            Kontakt
          </Heading>
          <Text variant="default">
            Wenn Sie ein technisches Problem hatten schreiben Sie uns direkt und
            wir helfen Ihnen.
          </Text>
        </ContactForm>
      </Flex>
    </Layout>
  );
};

export default Abgebrochen;
